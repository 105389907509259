import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
import InterviewsContainer from 'components/Sections/Interviews/InterviewsContainer'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IInterviewCard } from 'models/Interview'
import { IContextWithPageNav } from 'models/Context'

type InterviewListPageProps = {
    interviews: { nodes: IInterviewCard[] }
    pageSeo: ISeoQuery
}

const InterviewListPage: React.FC<
    PageProps<InterviewListPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const interviews = data.interviews.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }

    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <InterviewsContainer context={context} interviews={interviews} />
        </Layout>
    )
}

export default InterviewListPage

export const pageQuery = graphql`
    query InterviewsListPage($limit: Int!, $skip: Int!) {
        interviews: allContentfulInterviews(
            limit: $limit
            skip: $skip
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                slug
                date

                excerpt {
                    raw
                }

                thumbnail {
                    gatsbyImageData(width: 350)
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "interviews" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
