import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import PageNav from 'components/UIElements/Navigation/PageNav'
import InterviewsList from './InterviewsList'
// Models
import { IContextWithPageNav } from 'models/Context'
import { IInterviewCard } from 'models/Interview'
// Styles
import * as styles from './InterviewsContainer.module.css'

type InterviewsContainerProps = {
    interviews: IInterviewCard[]
    context: IContextWithPageNav
}

const InterviewsContainer: React.FC<InterviewsContainerProps> = ({
    interviews,
    context,
}) => {
    const { currentPage, totalPages } = context
    return (
        <div className={styles.interviews}>
            <Content>
                <InterviewsList interviews={interviews} />
                <PageNav
                    to="/interviews"
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Content>
            <DefaultSidebar />
        </div>
    )
}

export default InterviewsContainer
