import React from 'react'
// Components
import InterviewCard from './InterviewCard'
// Models
import { IInterviewCard } from 'models/Interview'

type InterviewsListProps = {
    interviews: IInterviewCard[]
}

const InterviewsList: React.FC<InterviewsListProps> = ({ interviews }) => {
    return (
        <>
            {interviews.map((interview) => (
                <InterviewCard key={interview.slug} interview={interview} />
            ))}
        </>
    )
}

export default InterviewsList
