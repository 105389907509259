import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getFormattedDate } from 'util/date'
// Models
import { IInterviewCard } from 'models/Interview'
// Styles
import * as styles from './InterviewCard.module.css'

type InterviewCardProps = {
    interview: IInterviewCard
}

const InterviewCard: React.FC<InterviewCardProps> = ({ interview }) => {
    const { slug, title, thumbnail, date } = interview
    const formatted_date = getFormattedDate(date)
    return (
        <article className={styles.interview_card}>
            <Link
                className={styles.interview_card_link}
                to={`/interviews/${slug}`}
            >
                <figure className={styles.interview_card_thumbnail}>
                    <GatsbyImage
                        className={styles.interview_card_thumbnail_img}
                        image={thumbnail.gatsbyImageData}
                        alt={title}
                    />
                    <span className={styles.interview_card_date}>
                        {formatted_date}
                    </span>
                </figure>
                <header className={styles.interview_card_header}>
                    <h2 className={styles.interview_card_title}>{title}</h2>
                </header>
            </Link>
        </article>
    )
}

export default InterviewCard
